// import images
import Logo from "../src/img/header/Logo PNG.png";
import GalleryImg1 from "../src/img/gallery/1.jpeg";
import GalleryImg2 from "../src/img/gallery/2.jpeg";
import GalleryImg3 from "../src/img/gallery/3.jpeg";
import GalleryImg4 from "../src/img/gallery/4.jpeg";
import GalleryImg5 from "../src/img/gallery/5.jpeg";
import GalleryImg6 from "../src/img/gallery/6.jpeg";
import GalleryImg7 from "../src/img/gallery/7.jpeg";
import GalleryImg8 from "../src/img/gallery/8.jpeg";
import GalleryImg9 from "../src/img/gallery/9.jpeg";
import GalleryImg10 from "../src/img/gallery/10.jpeg";
import GalleryImg11 from "../src/img/gallery/11.jpeg";
import GalleryImg12 from "../src/img/gallery/12.jpeg";
import GalleryImg13 from "../src/img/gallery/13.jpeg";
import GalleryImg14 from "../src/img/gallery/14.jpeg";
import GalleryImg15 from "../src/img/gallery/15.jpeg";
import GalleryImg16 from "../src/img/gallery/16.jpeg";
import QuoteImg from "../src/img/testimonial/quote.svg";
// import icons
import { GrFacebookOption } from "react-icons/gr";
import { IoMdArrowForward } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io";

export const headerData = {
  logo: Logo,
};

export const navData = {
  items: [
    { href: '/', name: "Home" },
    { href: "#gallery", name: "Gallery" },
    { href: "#about", name: "About" },
    { href: "#contact", name: "Contact" },
    // { href: '/', name: 'Interview' },
    // { href: '/', name: 'Articles' },
  ],
};

export const socialData = [
  { href: "https://www.facebook.com/profile.php?id=100092550017079" , target:"blank", icon: <GrFacebookOption /> },
  { href: "https://www.instagram.com/the_spark_exhibition/", icon: <IoLogoInstagram /> },
  // { href: "/", icon: <IoLogoPinterest /> },
  // { href: "/", icon: <IoLogoTwitter /> },
  // { href: "/", icon: <IoLogoYoutube /> },
];

export const heroData = {
  title: "The Spark Exhibits",
  subtitle: "Exhibition Stall Design & Services",
  btnText: "What we Provide",
  btnIcon: <IoMdArrowForward />,
};

export const aboutData = {
  // title: "My Story:",
  title1:
    "Briefing",
  subtitle1:
    "Our approach involves actively listening to our clients and meticulously gathering comprehensive details about their business. We conduct thorough research tailored to each client's specific business objectives, enabling us to provide exhibition stall designs that align with their desires.3D Design Visuals. We generate a variety of 3D design visuals based on your brief, budget, and our extensive research. Our team of skilled 3D and Graphics Designers possesses significant expertise, ensuring the delivery of a range of promising design options.",
  btnText: "Know more",

  // title: "My Story:",
  title2:
    "Stall Build & install",
  subtitle2:
    "Once the customized 3D stall design receives final approval, we assume complete responsibility for executing the booth, including the fabrication and installation of your exhibition stall.",
  
    title3:
    "Dismantle & Storage",
  subtitle3:
    "Once the trade show concludes triumphantly, our team stands prepared to dismantle your stall. Additionally, we offer the option to store the expo booth material in our warehouse for your future exhibition, at a nominal price.",

    title4:
    "3D Design Visuals",
  subtitle4:
    "Our experienced team of 3D and Graphics Designers will generate a range of design visuals, tailored to your brief, budget, and supported by thorough research. These options are aimed at providing you with a variety of optimistic design choices.",

    title5:
    "Onsite Supervision",
  subtitle5:
    "You don't need to worry about your stand building. We consistently oversee and monitor our onsite team, prioritizing our clients throughout the entire project. We keep clients informed about the progress of their project through timely updates, including images and videos directly from the site.",

    title6:
    "Optimize Your Feedback",
  subtitle6:
    "We highly value client feedback as it plays a crucial role in enhancing our outcomes. By optimizing your sentiments, we strive to develop a compelling stall concept that truly stands out.",

    

  btnIcon: <IoMdArrowForward />,
};

export const galleryData = {
  title: " Gallery:",
  btnText: "View all",
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      src: GalleryImg1,
      original: GalleryImg1,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg2,
      original: GalleryImg2,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg3,
      original: GalleryImg3,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg4,
      original: GalleryImg4,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg5,
      original: GalleryImg5,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg6,
      original: GalleryImg6,
      width: 464,
      height: 412,
    },
    {
      src: GalleryImg7,
      original: GalleryImg7,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg8,
      original: GalleryImg8,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg9,
      original: GalleryImg9,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg10,
      original: GalleryImg10,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg11,
      original: GalleryImg11,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg12,
      original: GalleryImg12,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg13,
      original: GalleryImg13,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg14,
      original: GalleryImg14,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg15,
      original: GalleryImg15,
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg16,
      original: GalleryImg16,
      width: 465,
      height: 412,
    },

  ],
};

export const interviewData = {
  title:
    "“Consider what you desire. Your tattoo artist will never tell you what tattoo to have.”",
  btnText: "Watch it now",
  btnIcon: <FaPlay />,
};

export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      "The Spark Exhibition is a company renowned for its specialization in exhibition and retail work. We take great pride in our exceptional expertise in this field. Our reputation has been built upon our ability to consistently deliver innovative ideas and meticulous attention to detail.Our main goal is to create memorable and unique exhibitions and retail projects that help their clients effectively promote their messages. With over  years of experience in the events, media, and advertising industries, We're experts at designing and organizing events that help our clients achieve their goals. We believe that every event is unique, so we create original and tailor-made concepts for each one. Despite our high-quality work, we still manage to deliver cost-effective solutions thanks to our in-house services. We're always up-to-date with the latest trends and provide integrated business solutions to give our clients the best service possible.",
    name: "The Spark Exhibitions",
    occupation: "@Team",
  },
  // {
  //   quoteImg: QuoteImg,
  //   message:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam culpa asperiores officia natus dignissimos autem possimus molestias aperiam?",
  //   name: "Douglas Hane",
  //   occupation: "Tattoo Artist",
  // },
];

export const contactData = {
  title: "Get in touch with Us:",
  info: [
    {
      title: "The Spark Exhibition",
      subtitle:
        "Feel free to reach out us",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "D-22 Kh No. 302/2 3rd Floor, Main 100ft Road Chattarpur Enclave, Phase - 2, New Delhi, Delhi 110074",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+91 9205565672",
      },
      email: {
        icon: <FaEnvelope />,
        address: "thesparkexhibition@gmail.com",
      },
      link: "Get location",
    },
    
  ],
  form: {
    name: "Write your name here",
    email: "Write your email address",
    message: "Write your messages here",
    btnText: "Send it",
  },
};

export const footerData = {
  about: {
    title: "About The Spark Exhibition",
    subtitle:
      "We'r more than happy to serve please feel free to get connected",
    address: {
      icon: <FaMapMarkerAlt />,
      name: "D-22 Kh No. 302/2 3rd Floor, Main 100ft Road Chattarpur Enclave, Phase - 2, New Delhi, Delhi 110074",
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: "+91 9205565672",
    },
    email: {
      icon: <FaEnvelope />,
      address: "thesparkexhibition@gmail.com",
    },
  },
  links: {
    title: "Useful links",
    items: [
      { href: "#about", name: "About us" },
      { href: "#gallery", name: "Gallery" },
      { href: "#contact", name: "Contact us" },
    ],
  },
  
  newsletter: {
    title: "Newsletter",
    subtitle:
      "We Will Get in Touch Soon 😊 😊",
    form: {
      placeholder: "Your email address",
      icon: <FiSend />,
    },
  },
};
