import React from 'react';
// import testimonial data
import { testimonialData } from '../data';
// import swiper react components
import { Swiper, SwiperSlide } from 'swiper/react';
// import swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import motion
import { motion } from 'framer-motion';
// import variants
import { fadeIn } from '../variants';
import img1 from "../img/gallery/test1.jpeg";
import img2 from "../img/gallery/test2.jpeg";
import img3 from "../img/gallery/test3.jpeg";

export const Testimonial = () => {
  return (
    <div id='about' style={{backgroundColor:"#f7f7f7"}}>
      <h1  style={{textAlign:"center"  , paddingTop:"130px ",fontSize:"4rem" , color:"black"}}>About Us</h1>
    <motion.section
      variants={fadeIn('up')}
      initial='hidden'
      whileInView={'show'}
      viewport={{ once: false, amount: 0.1 }}
      className='pb-[10px] lg:pb-[10px] lg:pt-0'
    >
      <div className='container mx-auto'>
        <Swiper>
          {testimonialData.map((slide, index) => {
            // destructure slide
            const { quoteImg, message, name, occupation } = slide;
            return (



              <section className='section'>
              <div className='container mx-auto'>
                <div className='flex flex-col xl:flex-row gap-y-16 text-[#333]'>
                  {/* text */}
                  <motion.div
                    variants={fadeIn('right')}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{ once: false, amount: 0.1}}
                    className='flex-1'
                  >
                   
       {/* 1st section */}

       <div className='text-2xl lg:text-[36px] leading-normal mb-6 lg:mb-12'>
                     {message}
                    </div>







                   
                  </motion.div>
                  {/* form */}
                  <motion.div
                    variants={fadeIn('left')}
                    initial='hidden'
                    whileInView={'show'}
                    viewport={{ once: false, amount: 0.1 }}
                    className='flex-1 xl:pl-[40px] flex justify-center items-center '
                  >
                   
                   <div className="grid " >
                    <img src={img1} alt="" style={{width:"100em", border:"50%"}} />
                    <img src={img2} alt="" style={{width:"100em" , border:"50%"}}/>
                    <img src={img3} alt="" style={{width:"100em" , border:"50%"}} />
                   </div>
                    
                  </motion.div>
                </div>
              </div>
            </section>




              // <SwiperSlide key={index}>
              //   <div className='max-w-[1200px] mx-auto flex flex-col items-center lg:flex-row lg:items-start gap-x-[38px] text-[gray]'>
              //     <div className='w-[154px] h-[109px] mb-5'>
              //       <img src={quoteImg} alt='' />
              //     </div>
              //     <div className='flex-1 lg:mt-[54px]'>
              //       <div className='text-2xl lg:text-[36px] leading-normal mb-6 lg:mb-12'>
              //         {message}
              //       </div>
              //       <div className='flex items-center text-xl lg:text-2xl font-primary'>
              //         <div className='font-semibold'>{name}</div>
              //         <span className='mx-4'>|</span>
              //         <div className='font-light'>{occupation}</div>
              //       </div>
              //     </div>
              //   </div>
              // </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </motion.section>
    </div>
  );
};

export default Testimonial;
