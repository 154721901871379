import React from 'react'
import { useRef } from 'react';
import emailjs from "@emailjs/browser"

const Form = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm(
        'service_daxbj48',
       'template_y6rcuf7', 
       form.current, '_Z62UTH_Oa2kTc5lI'
       )
        .then((result) => {
            console.log(result.text);
            window.location.reload();
        }, (error) => {
            console.log(error.text);
        });
    };
  return (
    <div>
        <div className="flex flex-wrap justify-center">
             <div className="w-full max-w-lg shadow-md bg-white rounded px-8 pt-6 pb-8 mb-4 ">
             <h1 style={{textAlign:"center", fontSize:"2em"}}>Send Enquiry</h1>

             {/* <form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form> */}

    
              <form  ref={form} onSubmit={sendEmail} className=" rounded px-8 pt-6 pb-8 mb-4 text-[gray]">


              <div className='flex space-x-6 '>
               
                  
               
                    <div className="" >

                        <label htmlFor="eventname" className="text-md font-semibold">
                            Your Name <span className="text-[#312f30]">*</span>
                        </label>

                        <input
                             style={{marginTop:"10px"}}
                            type="text"
                            name="user_name" 
                            required
                          
                            
                            className="w-full h-8 rounded-md border-[1px] text-gray-900 p-2"
                        />
                       
                    </div>
                    <div className="">
                        <label htmlFor="eventname" className="text-md font-semibold">
                            Email <span className="text-[#312f30]">*</span>
                        </label>
                        <input
                         style={{marginTop:"10px"}}
                            type="email"
                            name="user_email"
                           required
                            
                            className="w-full h-8 rounded-md border-[1px] text-gray-900 p-2"
                        />
                       
                    </div>
                
                </div>
                <div className='flex space-x-6 mt-5'>
                    <div className="" >

                        <label htmlFor="eventname" className="text-md font-semibold">
                           Company Name <span className="text-[#312f30]"></span>
                        </label>

                        <input
                             style={{marginTop:"10px"}}
                            type="text"
                            name="user_company"
                            
                            className="w-full h-8 rounded-md border-[1px] text-gray-900 p-2"
                        />
                       
                    </div>
                    <div className="">
                        <label htmlFor="eventname" className="text-md font-semibold">
                           Phone <span className="text-[#312f30]">*</span>
                        </label>
                        <input
                         style={{marginTop:"10px"}}
                            type="number"
                            name="user_phone"
                           required
                            
                            className="w-full h-8 rounded-md border-[1px] text-gray-900 p-2"
                        />
                       
                    </div>

                </div>

                

                <div className='flex space-x-6 mt-5'>
                    <div className="" >

                        <label htmlFor="eventname" className="text-md font-semibold ">
                      Message<span className="text-[#312f30]">*</span>
                        </label> <br />
                        <textarea name="message" rows={40} cols={40} className="w-full h-8 rounded-md border-[1px] text-gray-900 p-2"  style={{marginTop:"10px" , height:"15vh" }}/>

                       
                       
                    </div>

                </div>

               

<div className="flex justify-center">
                    <input
                   
                        className="uppercase font-semibold px-8 rounded-xl cursor-pointer py-2 mt-[20px] text-[#f8f6f6] "
                        style={{ backgroundColor: "black",border:"1px solid #eee" }}
                        type="submit"
                        value="Send"
                    />
                </div>
              </form>
             </div>

        </div>
    </div>
  )
}

export default Form