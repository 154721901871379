import React from 'react'

const AboutHeader = () => {
  return (
    <div className=' bg-black max-w-full font-bold text-[#fff]' style={{marginTop:"1em", width:"100%", marginBottom:"-3em", textAlign:"center"} }>
        <div className="flex  flex-col " style={{margin:"auto", width:"100%", alignItems:"center", padding:"5px"}}>
           
            <h1 style={{fontSize:"24px", margin:"25px 0px",textAlign:"center"}}>The Spark Exhibition Standard Operation Procedure</h1> 
            <h3  style={{ margin:"25px 0px"}}>We understand the exhibition stall ideas of your Brand and the Objective. After that, we offer an outstanding recommendation for your exhibition Stall Designing construction with the rightful approach.</h3>
           
           
           
        </div>
    </div>
  )
}

export default AboutHeader